<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="p-3">
        <b-row v-if="isMobile">
          <b-col>
            <multiselect
              v-model="selectedRole"
              track-by="value"
              label="text"
              :options="roles.options"
              deselect-label=""
              select-label=""
              placeholder=""
              :multiple="false"
              @input="onRoleDropdownInput"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="2" md="12" sm="12" v-if="!isMobile">
            <b-overlay
              :show="isLoading"
              :opacity="0.5"
              spinner-variant="secondary"
              rounded="sm"
              z-index="1000"
              :class="[isLoading ? 'pt-5' : '']"
            >
              <perfect-scrollbar :options="{ suppressScrollX: true }">
                <b-list-group class="h-100">
                  <template v-for="(role, index) in roles.options">
                    <b-list-group-item
                      :key="index"
                      button
                      @click="onRoleItemClick(role)"
                      :variant="roles.selected === role.value ? 'primary' : ''"
                      >{{ role.text }} ({{ role.value }})</b-list-group-item
                    >
                  </template>
                </b-list-group>
              </perfect-scrollbar>
            </b-overlay>
          </b-col>

          <b-col lg="10" md="12" sm="12">
            <menu-permissions-tree
              ref="menuPermissionsTree"
              :is-embeded="false"
              :mode="$constants.FORM_MODE.VIEW"
              :role-id="roles.selected"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";

import Multiselect from "vue-multiselect";

import "vue-multiselect/dist/vue-multiselect.min.css";

import MenuPermissionsTree from "./MenuPermissions/MenuPermissionsTree.vue";

export default {
  name: "MenuPermissions",

  components: {
    Multiselect,
    MenuPermissionsTree
  },

  data: function() {
    return {
      isMobile: isMobile,
      isLoading: false,
      selectedRole: {},
      roles: {
        selected: undefined,
        options: []
      }
    };
  },

  computed: {},

  mounted() {
    let self = this;

    self.isLoading = true;

    this.$api.get("roles").then(response => {
      self.isLoading = false;

      if (!response.length) {
        self.$form.makeToastError("List of roles is empty");
        return;
      }

      self.roles.options = response.map(u => ({
        value: u.roleid,

        text: u.rolename
      }));

      self.selectedRole = self.roles.options[0];

      self.roles.selected = self.roles.options[0].value;
    });
  },

  methods: {
    onRoleDropdownInput(value) {
      this.roles.selected = value.value;
    },

    onRoleItemClick(e) {
      this.roles.selected = e.value;
    }
  }
};
</script>

<style scoped>
.card {
  height: 85vh;
}

::v-deep .treeselect-container .vue-treeselect__multi-value {
  height: 300px !important;
}
</style>
